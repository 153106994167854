import React, {FC, useState} from "react";
import {
    Alert,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {getAuth} from "firebase/auth";
import {useAuth} from "../../App";

type AddUserDialogProps = {
    open: boolean;
    onClose: () => React.Dispatch<boolean>;
};

export const ImportUsersDialog: FC<AddUserDialogProps> = ({
                                                              open,
                                                              onClose,
                                                          }: AddUserDialogProps) => {
    const [successes, setSuccesses] = useState<string[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [emails, setEmails] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingOne, setLoadingOne] = useState(false);
    const auth = useAuth();

    const resetForm = () => {
        setSuccesses([]);
        setErrors([]);
        setEmails("");
    };

    const onSave = async (e) => {
        e.preventDefault();

        setLoading(true);
        setErrors([]);
        setSuccesses([]);

        for (let email of emails.split("\n")) {

            setLoadingOne(true);
            const app = auth.user.appConfig.app;
            const token = await getAuth().currentUser.getIdToken();
            await fetch(
                "https://ledger.livemegawatt.com/add-user",
                // "http://localhost:3000/add-user",
                {
                    headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json",
                    },
                    method: "POST",
                    body: JSON.stringify({
                        email,
                        app,
                    }),
                    redirect: "follow",
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    console.log(result);
                    switch (result.status) {
                        case "success":
                            setSuccesses(successes => {
                                successes.push("User added successfully: " + email);
                                return successes;
                            });
                            break;
                        case "already-added":
                            setErrors(errors => {
                                errors.push("This user is already added to the app: " + email);
                                return errors;
                            });
                            break;
                        case "unknown":
                        case "error":
                        default:
                            setErrors(errors => {
                                errors.push("Unable to add this user to the app: " + email);
                                return errors;
                            });
                            break;
                    }
                })
                .finally(() => {
                    setLoadingOne(false);
                });

        }

        setLoading(false);
        return false;

    };

    // getAuth().currentUser.getIdToken().then(console.log);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Import users</DialogTitle>
            <DialogContent>
                {errors && (
                    errors.map((error, i) => <Alert severity="error" sx={{mt: 2}} key={"e" + i}>
                        {error}
                    </Alert>)
                )}
                1 email address per line
                <form onSubmit={onSave}>
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        id="email"
                        name="email"
                        label="Emails"
                        autoFocus
                        multiline
                        autoComplete="email"
                        value={emails}
                        onChange={(e) => setEmails(e.target.value)}
                    />

                    <Alert severity={"info"}>
                        Number of email addresses: {emails.split("\n").length}
                    </Alert>

                    {successes && successes.length > 0 &&
                        <Alert severity="success" sx={{mt: 2}}>
                            {successes.map((success, i) => <div key={"s" + i}>{success}</div>)}
                        </Alert>}

                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={loading}
                        sx={{mt: 3, mb: 2}}
                    >
                        Submit
                    </LoadingButton>
                </form>
            </DialogContent>
        </Dialog>
    );
};
